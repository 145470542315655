import React from 'react'

function _404() {
    return (
        <div>
            This Page can't be found
        </div>
    )
}

export default _404
